import React, { useState } from 'react';
import './Question.scss';
import { questions } from './questions';
import logo from './image/logo.svg';
import planets from './planets.js';
import ProgressBar from '@ramonak/react-progress-bar';
import Consigli from './files/quanta_terra_usi_consigli_01.pdf';

const Question = (props) => {
	const [questionId, setQuestionId] = useState(props.questionId);
	const [question, setQuestion] = useState(props.question);
	const [result, setResult] = useState(0);
	const [activeButton, setActiveButton] = useState(null);

	const start = () => {
		setResult(0);
		setQuestion(questions[0]);
		setQuestionId(0);
	};

	const handler = (index, point) => {
		setActiveButton(index);
		setTimeout(() => {
			setActiveButton(null);
			if (questions.length - 1 !== questionId) {
				setQuestion(questions[questionId + 1]);
			}
			setResult(result + point);
			setQuestionId(questionId + 1);
		}, 200);
	};

	const startAgain = () => {
		setResult(0);
		setQuestion('');
		setQuestionId(-1);
	};

	const selectResult = (result, platet = true) => {
		const range = { min: 75, max: 700 };
		let i = parseInt((result - range.min) / ((range.max - range.min) / planets.length));
		i = Math.max(0, i);
		i = Math.min(planets.length - 1, i);

		return platet ? planets[i] : i;
	};

	const goSuggestion = () => {
		setQuestionId(questionId + 1);
	};

	let content;
	if (questionId === -1) {
		content = (
			<>
				<div className="banner">
					<img src={logo} className="logo" alt="Quanta Terra Usi" />
				</div>
				<p style={{ textAlign: 'center', fontSize: '0.9rem', maxWidth: '75%', margin: '20px auto' }}>
					Ciao, ti diamo il benvenuto in <b>Quanta Terra Usi?</b>, un test volto a misurare l’impatto ambientale del tuo
					stile di vita. <br />
					Rispondi alle domande e scopri quante Terre servirebbero se tutti vivessero come te!
					<br></br>
					<button className="btn-start" onClick={start} style={{ width: window.self === window.top ? '' : '10rem' }}>
						{window.self === window.top ? 'Avvia' : 'Avvia il quiz'}
					</button>
				</p>
			</>
		);
	} else if (questionId === questions.length) {
		content = (
			<div className="classifica">
				<h2>
					Se tutti si comportassero come te<br></br>per vivere servirebbero:
				</h2>
				<div className="div-planets">
					<img className="planets" src={selectResult(result)} alt="Pianeti result"></img>
				</div>
				<div className="btn-container">
					<button className="btn-finish" onClick={goSuggestion}>
						Approfonsisci
					</button>
				</div>
			</div>
		);
	} else if (questionId === questions.length + 1) {
		let planetTemp = selectResult(result, false);
		let suggestion =
			planetTemp === 0 ? (
				<p>
					Complimenti! La Terra può sostenere il tuo stile di vita, continua così! Sii un esempio per la tua famiglia, i
					tuoi amici e la tua scuola, insieme si può cambiare il mondo.<br></br>Di seguito troverai alcuni spunti per
					diminuire ulteriormente il tuo impatto ambientale, continua a dare il tuo contributo per mantenere la Terra un
					posto migliore per tutti. Sii protagonista del cambiamento!
				</p>
			) : planetTemp >= 1 && planetTemp <= 3 ? (
				<p>
					Attenzione, la tua impronta ecologica è superiore a quella che la Terra può sostenere, ma non disperare… di
					seguito troverai alcuni spunti per iniziare a diminuire il tuo impatto ambientale.<br></br>Coinvolgi la tua
					famiglia, i tuoi amici, la tua scuola per diventare protagonisti del cambiamento! La Terra ti sarà grata.
				</p>
			) : (
				<p>
					Ti aspettavi di avere questo impatto sulla Terra? La tua impronta ecologica è di molto superiore a quella che
					il Pianeta può sostenere, ma non disperare! <br></br>Puoi cambiare il tuo stile di vita per renderlo più
					sostenibile ed ecologico e la Terra ti sarà grata. Di seguito troverai alcuni spunti per iniziare a diminuire
					il tuo impatto ambientale!
				</p>
			);
		const decalog = (
			<ol>
				<li>Spegni sempre il computer, televisione e altri dispositivi elettronici quando non li utilizzi.</li>
				<li>
					Se la tua scuola èvicina, valuta di raggiungerla in bici o a piedi. Se è lontana, preferisci i mezzi pubblici
					alla macchina e, se vai in macchina, prova ad organizzare con i tuoi genitori e compagni un sistema di
					car-sharing.
				</li>
				<li>
					Nel mondo 785 milioni di persone non hanno accesso all'acqua potabile: in Italia l'acqua del rubinetto è
					controllata e sicura. Preferisci l'acqua del rubinetto a quella in bottiglie di plastica e chiedi alla tua
					scuola di installare erogatori per riempire le borracce.
				</li>
				<li>
					Raccogli e riutilizza l’acqua con cui hai lavato la frutta e la verdura quando possibile, puoi impiegarla per
					innaffiare le piante in casa.
				</li>
				<li>
					Fai la raccolta differenziata a seconda delle regole del tuo comune. Chiedi alla tua scuola dei cestini per
					ogni classe se non sono ancora presenti.
				</li>
				<li>
					Scegli di acquistare oggetti di seconda mano prima di oggetti nuovi e divertiti a scambiare ciò che non usi
					più con amici e parenti.
				</li>
				<li>Evita prodotti con imballaggio usa e getta, preferisci prodotti sfusi o con imballaggio riciclabile</li>
				<li>
					Insieme ai tuoi genitori, informati su come acquistare in maniera più sostenibile: puoi cominciare a conoscere
					i contadini del mercato vicino a te per saperne di più sulla produzione della frutta e verdura di stagione.
				</li>
				<li>
					Sii curioso anche a tavola: opta per alternative vegetariane e vegane e cerca di limitare il consumo di carne,
					pesce e derivati animali.
				</li>
				<li>
					Fai del volontariato ambientale: è un’occasione per imparare, fare del bene ed incontrare nuovi amici!
					Informati sulle iniziative come quelle volte alla pulizia della tua città o quartiere
				</li>
			</ol>
		);
		content = (
			<div className="suggestion">
				<h2>Riduci la tua impronta</h2>
				<div className="scrollbar">
					{suggestion}
					<h3 style={{ textAlign: 'center' }}>10 azioni per aiutare la Terra</h3>
					{decalog}
				</div>
				<div className="btn-container-no-wrap">
					<a href={Consigli} target="_blank" rel="noopener noreferrer" download>
						<button className="btn-finish">Download PDF</button>
					</a>
					<button className="btn-finish" onClick={startAgain}>
						Ripeti il Test
					</button>
				</div>
			</div>
		);
	} else {
		content = (
			<>
				<div className="q-number">
					<strong>{questionId + 1}</strong>/{questions.length}
				</div>
				<div className="question">{question.domanda}</div>
				<div className="answers">
					{question.risposte.map((risposta, index) => {
						return (
							<div
								key={index}
								className={`answer ${activeButton === null ? 'answer-action' : ''}`}
								onClick={activeButton === null ? (event) => handler(index, risposta.punti) : null}
								style={{
									backgroundColor: activeButton === index ? 'rgba(0, 0, 0, 0.15)' : 'white',
								}}
							>
								{risposta.testo}
							</div>
						);
					})}
				</div>
				<div className="status-bar">
					<ProgressBar
						bgColor="#63C988"
						labelAlignment="outside"
						labelColor="black"
						isLabelVisible={false}
						borderRadius="false"
						height="8px"
						completed={Math.round(((questionId - 0) * 100) / (questions.length - 0))}
					/>
				</div>
			</>
		);
	}
	return (
		<div className="page">
			<div
				className={`container ${questionId === questions.length + 1 ? 'big' : ''} ${
					window.self === window.top && questionId !== -1 ? 'with-logo' : ''
				}`}
			>
				{content}
			</div>
		</div>
	);
};

export default Question;
