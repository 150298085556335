import logo from './image/logo.svg';
import './assets/easyreading/easyreading.css';
import './App.scss';
import { Link } from 'react-router-dom';

function App() {
	return (
		<div className="page">
			<div className={`container`}>
				<img src={logo} className="logo" alt="Quanta Terra Usi" />
				<Link to="/quiz">Quiz</Link>
			</div>
		</div>
	);
}

export default App;
