exports.questions = [
	{
		domanda: 'Di quante persone è composta la tua famiglia?',
		risposte: [
			{ testo: '1', punti: 30 },
			{ testo: '2', punti: 25 },
			{ testo: '3', punti: 20 },
			{ testo: '4', punti: 15 },
			{ testo: 'più di 4', punti: 10 },
		],
	},
	{
		domanda: 'Con cosa è riscaldata la tua casa?',
		risposte: [
			{ testo: 'Gas Metano', punti: 30 },
			{ testo: 'Elettricità', punti: 40 },
			{ testo: 'Gasolio', punti: 50 },
			{ testo: 'Fonti rinnovabili', punti: 0 },
		],
	},
	{
		domanda: 'Quanti rubinetti ci sono nella tua abitazione?',
		risposte: [
			{ testo: 'Fino a 2', punti: 5 },
			{ testo: 'Da 3 a 5', punti: 10 },
			{ testo: 'Da 6 a 8', punti: 15 },
			{ testo: 'Da 9 a 11', punti: 20 },
			{ testo: 'Più di 11', punti: 25 },
		],
	},
	{
		domanda: 'In che tipo di casa abiti?',
		risposte: [
			{ testo: 'Condominio', punti: 20 },
			{ testo: 'Casa a schiera', punti: 30 },
			{ testo: 'Casa indipendente', punti: 40 },
		],
	},
	{
		domanda: 'In una settimana quante volte mangi carne o pesce?',
		risposte: [
			{ testo: 'Mai', punti: 0 },
			{ testo: 'Fino a 3', punti: 10 },
			{ testo: 'Da 4 a 6', punti: 20 },
			{ testo: 'Da 7 a 10', punti: 35 },
			{ testo: 'Più di 10', punti: 50 },
		],
	},
	{
		domanda: 'In una settimana quanti pasti si cucinano in casa (compresa la colazione)?',
		risposte: [
			{ testo: 'Fino a 9', punti: 25 },
			{ testo: 'Da 10 a 13', punti: 20 },
			{ testo: 'Da 14 a 18', punti: 15 },
			{ testo: 'Più di 18', punti: 10 },
		],
	},
	{
		domanda: 'La tua famiglia preferisce acquistare alimenti prodotti localmente?',
		risposte: [
			{ testo: 'Sì', punti: 5 },
			{ testo: 'No', punti: 10 },
			{ testo: 'A volte', punti: 15 },
			{ testo: 'Raramente', punti: 20 },
			{ testo: 'Non lo so', punti: 25 },
		],
	},
	{
		domanda:
			"Nell'ultimo anno quanti di questi oggetti avete acquistato: stereo, televisore, computer, smartphone, tablet, automobile, mobili, elettrodomestici.",
		risposte: [
			{ testo: 'Nessuno', punti: 0 },
			{ testo: 'Fino a 2', punti: 15 },
			{ testo: 'Da 3 a 5', punti: 30 },
			{ testo: 'Più di 6', punti: 45 },
		],
	},
	{
		domanda: "Nell'ultimo anno hai acquistato articoli a risparmio energetico?",
		risposte: [
			{ testo: 'Si', punti: 0 },
			{ testo: 'No', punti: 25 },
		],
	},
	{
		domanda: 'Quale mezzo usi più spesso per muoverti?',
		risposte: [
			{ testo: 'Bicicletta o piedi', punti: 15 },
			{ testo: 'Ibrida / Elettrica / Metano', punti: 30 },
			{ testo: 'Utilitaria', punti: 50 },
			{ testo: 'Berlina', punti: 90 },
			{ testo: 'Van o SUV (anche se ibridi)', punti: 130 },
		],
	},
	{
		domanda: 'Che mezzo utilizzi per recarti a scuola?',
		risposte: [
			{ testo: 'Automobile', punti: 50 },
			{ testo: 'Autobus', punti: 25 },
			{ testo: 'Scuolabus', punti: 20 },
			{ testo: 'Motorino', punti: 10 },
			{ testo: 'Piedi o bicicletta', punti: 0 },
		],
	},
	{
		domanda: "Dove hai passato le vacanze nel corso dell'ultimo anno?",
		risposte: [
			{ testo: 'Regione', punti: 0 },
			{ testo: 'Italia', punti: 30 },
			{ testo: 'Europa', punti: 40 },
			{ testo: 'Altro continente', punti: 70 },
		],
	},
	{
		domanda: "Quante volte utilizzate l'automobile per il fine settimana?",
		risposte: [
			{ testo: 'Mai', punti: 0 },
			{ testo: 'Fino a 2', punti: 10 },
			{ testo: 'Da 3 a 5', punti: 20 },
			{ testo: 'Da 6 a 8', punti: 30 },
			{ testo: 'Più di 8', punti: 40 },
		],
	},
	{
		domanda:
			'Cerchi di ridurre i tuoi rifiuti acquistando prodotti ecologici e con imballaggi ridotti o riutilizzabili?',
		risposte: [
			{ testo: 'Sì, sempre', punti: 0 },
			{ testo: 'A volte', punti: 10 },
			{ testo: 'Raramente', punti: 15 },
			{ testo: 'No', punti: 25 },
		],
	},
	{
		domanda: 'Quanti sacchi della spazzatura produci ogni settimana?',
		risposte: [
			{ testo: 'Nessuno', punti: 0 },
			{ testo: '1', punti: 5 },
			{ testo: '2', punti: 10 },
			{ testo: 'Da 3 a 5', punti: 20 },
			{ testo: 'Più di 5', punti: 30 },
		],
	},
	{
		domanda: 'Fai la raccolta differenziata di carta, vetro e plastica?',
		risposte: [
			{ testo: 'Sì, sempre', punti: 5 },
			{ testo: 'A volte', punti: 10 },
			{ testo: 'Raramente', punti: 15 },
			{ testo: 'No', punti: 20 },
		],
	},
	{
		domanda: 'Fate il compostaggio domestico a casa?',
		risposte: [
			{ testo: 'Sì, sempre', punti: 5 },
			{ testo: 'A volte', punti: 10 },
			{ testo: 'Raramente', punti: 15 },
			{ testo: 'No', punti: 20 },
		],
	},
];
