import planet1 from './image/pianeti/1_p.png';
import planet15 from './image/pianeti/15_p.png';
import planet2 from './image/pianeti/2_p.png';
import planet25 from './image/pianeti/25_p.png';
import planet3 from './image/pianeti/3_p.png';
import planet35 from './image/pianeti/35_p.png';
import planet4 from './image/pianeti/4_p.png';
import planet45 from './image/pianeti/45_p.png';
import planet5 from './image/pianeti/5_p.png';
import planet55 from './image/pianeti/55_p.png';
import planet6 from './image/pianeti/6_p.png';

const planets = [
	planet1,
	planet15,
	planet2,
	planet25,
	planet3,
	planet35,
	planet4,
	planet45,
	planet5,
	planet55,
	planet6,
];

export default planets;
